import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [

  {
    path: '/admin', title: 'Dashboard', icon: 'bx bx-home-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    path: '', title: 'Finanzas', icon: 'bx bx-dollar', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin/finance/payrolls',  title: 'Nómina', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Reportes', icon: 'lni lni-bar-chart', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin/reports/general',  title: 'Reportes Generales', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Caja', icon: 'lni lni-dropbox', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin/drawers/assignment' , title: 'Asignar cajero',      icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/drawers/movements',   title: 'Movimientos de caja', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/drawers/withdrawals', title: 'Retiros',             icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Artículos', icon: 'bx bx-list-ol', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin/items/category', title: 'Líneas', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/items/item', title: 'Productos', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/items/service', title: 'Servicios', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Altas', icon: 'bx bx-pencil', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin/records/provider', title: 'Proveedores', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/records/employee', title: 'Empleados', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/records/client', title: 'Clientes', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/records/branchoffice', title: 'Sucursales', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },
  {
    path: '', title: 'Sistema', icon: 'lni lni-coffee-cup', class: 'sub', badge: '', badgeClass: '', isExternalLink: false,
    submenu: [
      { path: '/admin/system/goals', title: 'Objectivos Financieros', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/system/notify', title: 'Notificaciones', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { path: '/admin/system/config', title: 'Configuración', icon: 'bx bx-right-arrow-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    ]
  },

];