import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { errorHandler, GlobalVariable } from 'src/environments/environment';
import { IConfig } from './config.interface';
import { IPrinterDB } from './site/sales/sales.interface';
import { TokenStorageService } from './tokens/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private apiURL = GlobalVariable.BASE_API_URL;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(public httpClient: HttpClient,
              public storageService: TokenStorageService) { }

  getAll(): Observable<IConfig[]> {
    return this.httpClient.get<IConfig[]>(this.apiURL + 'users/configs/')
      .pipe(
        catchError(errorHandler)
      )
  }

  updateConfig(config: IConfig): Observable<IConfig> {
    return this.httpClient.put<IConfig>(this.apiURL + 'users/configs/' + config.id + '/', JSON.stringify(config), this.httpOptions)
      .pipe(
        catchError(errorHandler)
      )
  }

  localPrint(data:IPrinterDB): Observable<any> {
    let printerServer = this.storageService.getConfig('printer');

    if( !printerServer ) {
      printerServer = 'http://localhost:7000/';
    }

    return this.httpClient.post<any>(printerServer, JSON.stringify(data), this.httpOptions)
      .pipe(
        catchError(errorHandler)
      );
  }

  
}