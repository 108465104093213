import { throwError } from "rxjs";

export const environment = {
  production: true
};

export const GlobalVariable = Object.freeze({
  BASE_API_URL: 'https://www.api.sinergiamedical.com.mx/v1/',
  AUTH_API_URL: 'https://www.api.sinergiamedical.com.mx/auth/',
  MEDIA_URL:    'https://www.api.sinergiamedical.com.mx/assets/',
  REMOTE_HOST:  'https://www.api.sinergiamedical.com.mx'
});

export function errorHandler(error: any) {
let errorMessage = '';
if (error.error instanceof ErrorEvent) {
  errorMessage = error.error.message;
} else {
  errorMessage = error.error;
}
return throwError(() => { return errorMessage; });
}

export enum Roles {
ADMIN       = 'PROPIETARIO',
MANGER      = 'GERENTE',
COSMIATRIST = 'COSMEATRA',
CASHIER     = 'CAJERO'
}
