import { Component } from '@angular/core';
import { TokenStorageService } from './tokens/token-storage.service';
import { AuthService } from './auth/auth.service';
import { NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from './site/profile/profile.service';
import { CartService } from './site/cart/cart.service';
import { BranchesService } from './admin/records/branches/branches.service';
import { IProfile } from './site/profile/profile.interface';
import { BranchVoid, IBranch } from './admin/records/branches/branches.interface';
import { ConfigService } from './config.service';
import { IConfig } from './config.interface';
import { config } from 'rxjs';
import { NotificationService } from './notification.service';
import { Router } from '@angular/router';
import { DrawerVoid } from './admin/drawers/drawers.interface';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgbTooltipConfig]
})
export class AppComponent {
  title = 'spa';

  constructor(private storageService: TokenStorageService,
              private authService: AuthService,
              config: NgbTooltipConfig,
              private profileService: ProfileService,
              private cartService: CartService,
              private branchService: BranchesService,
              private configService: ConfigService,
              private notifyService: NotificationService,
              private router: Router) { 
    config.openDelay = 1200;
    config.placement = "bottom";
    config.tooltipClass = "customTooltip"

  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      const user = this.storageService.getUser();

      this.profileService.getProfile(user.id).subscribe((data:IProfile) => {

        this.storageService.saveUser(this.storageService.isPersistent(), data);

        if( data.drawer ) {
          this.cartService.updateDrawer(data.drawer);
          this.branchService.getBranch(data.drawer.branch).subscribe((data: IBranch) => {
            this.cartService.updateBranch(data);
          });
        }
        else {
          this.cartService.updateDrawer(DrawerVoid);
          this.cartService.updateBranch(BranchVoid);
        }
      });

      this.configService.getAll().subscribe({
        next: (data: IConfig[]) => {
          data.forEach(config => {
            this.storageService.setConfig(config.attribute, config.value);
          });
        },
        error: (error) => {
          this.notifyService.showError('No se pudo obtener la configuración del sistema','Configuración');
        }
      });
    }
    else {
      this.router.navigateByUrl('auth/login');
    }
  }
}
